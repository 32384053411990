<template>
    <v-container fill-height>
        <v-layout align-top justify-center>
            <v-flex xs12 sm10 md10 lg8>
                <v-sheet color="white" elevation="20" class="pa-2">
                    <v-data-table :headers="headers" :items="credit_notes" :items-per-page="10" item-key="id" mobile-breakpoint="0"
                        sort-by="name" class="elevation-0" :loading="loading_status" loader-height="10"
                        loading-text="Cargando ...">
                        <template #top>
                            <v-toolbar flat>
                                <v-toolbar-title>Notas Credito</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.cufe_n`]="{ item }">
                            <v-icon small class="mr-2" v-if="item.cufe_n">
                                mdi-check-all
                            </v-icon>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon small class="mr-2" @click="get_e_doc(item)">
                                mdi-magnify
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-sheet>
            </v-flex>
        </v-layout>
        <noteViewer :item="item" :dialog="dialog" @close="dialog = false" @refresh="refresh()" />
    </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import noteViewer from "../components/note_viewer.vue";

export default {
    components: { noteViewer },
    data() {
        return {
            errors: [],
            valid: false,
            loading_status: false,
            f_required: [(v) => !!v || "Requerido"],
            f_email: [
                (v) => !!v || "Correo valido",
                (v) => /.+@.+/.test(v) || "Correo no valido",
            ],
            imageUrl: null,
            dialog: false,
            headers: [
                {
                    text: "Fecha",
                    align: "start",
                    sortable: true,
                    value: "issuedate",
                    dataType: "text",
                },
                {
                    text: "Documento",
                    align: "start",
                    sortable: true,
                    value: "doc_number",
                    dataType: "text",
                },

                {
                    text: "Factura",
                    align: "start",
                    sortable: true,
                    value: "invoice",
                    dataType: "text",
                },
                {
                    text: "Valor",
                    align: "end",
                    sortable: true,
                    value: "doc_value",
                    dataType: "number",
                },
                {
                    text: "CUFE",
                    align: "end",
                    sortable: true,
                    value: "cufe_n",
                    dataType: "number",
                },
                { text: "", align: "end", value: "actions", sortable: false, width: "5" },
            ],
            credit_notes: [],
            item: { doc: null, items: [], payments: [] },
        };
    },
    mounted() {
        this.get_notes();
    },
    methods: {
        refresh() {
            this.get_notes();
        },
        get_notes() {
            this.loading_status = true;
            var qry = {
                store: window.store.store_id,
                table: "docs",
            };
            qry.filters = [
                {
                    field: "doc_type",
                    operator: "=",
                    value: "NC",
                },
            ];
            webserver("get_table", qry, (data) => {
                this.loading_status = false;
                this.credit_notes = data;
            });
        },
        get_e_doc(e) {
            var doc = {
                store: e.store,
                issueDate: e.issuedate,
                doc_number: e.doc_number,
            };
            webserver("get_e_doc", doc, (data) => {
                this.item = data;
                this.dialog = true;
                data.doc.doc_type = "NC";
                this.loading_vendors = false;
            });
        },
    },
};
</script>

<style>
</style>
